import Typography, { TypographyOptions } from "typography"
// import fairyGateTheme from "typography-theme-fairy-gates"

const config: TypographyOptions = {
  googleFonts: [
    {
      name: `Amatic SC`,
      styles: [`400`],
    },
    {
      name: `Indie Flower`,
      styles: [`400`],
    },
  ],
  headerFontFamily: [`Amatic SC`, `cursive`],
  bodyFontFamily: [`Indie Flower`, `cursive`],
  baseFontSize: `24px`,
  overrideStyles: (props, typographyOptions) => ({
    a: {
      color: `#fb251b`,
      textDecoration: `none`,
    },
    "a:hover,a:active": {
      color: typographyOptions.bodyColor,
    },
    "h1,h2,h3,h4,h5,h6": {
      marginTop: props.rhythm(2),
    },
  }),
}

const typography = new Typography(config)

export const { scale, rhythm, options } = typography
export default typography
