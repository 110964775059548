import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { rhythm } from '../utils/typography'

interface IProps {
  data: {
    allMarkdownRemark: {
      totalCount: number,
      edges: Array<{
        node: {
          id: string,
          frontmatter: {
            title: string,
            date: string,
          }
          excerpt: string,
        },
      }>,
    },
  }
}

export default ({ data }: IProps) => {
  return (
    <Layout>
      <h1>Little blog</h1>
      <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div>
          <h3 style={{ marginBottom: rhythm(1 / 4), display: `inline` }}>{node.frontmatter.title}</h3>
          <h4
            style={{ color: `#AAA`, display: `inline-block`, marginLeft: rhythm(1 / 5) }}>
            - {node.frontmatter.date}
          </h4>
          <p>{node.excerpt}</p>
        </div>
      ))}
    </Layout >
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          excerpt
        }
      }
    }
  }
`
